<template>
  <div class="aboutus">
    <div class="title">{{ $t("about.title1") }}</div>
    <div class="container">
      <div class="content" style="width:700px;">
        <div class="intro" data-aos="fade-up">
          <div class="intro-content">{{ $t("about.intro") }}</div>
        </div>
      </div>
      <!-- 经营历史 -->
      <div class="content">
        <div class="history">
          <div class="title-item">
            <h4 class="title-text">{{ $t("about.title3") }}</h4>
          </div>
          <div class="roadmap">
            <div class="roadmap-content">
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time11") }}</h4>
                  <p>{{ $t("about.ht11") }}</p>
                </div>
              </div>
              <div class="roadmap-item right" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time10") }}</h4>
                  <p>{{ $t("about.ht10") }}</p>
                </div>
              </div>
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time9") }}</h4>
                  <p>{{ $t("about.ht9") }}</p>
                </div>
              </div>
              <div class="roadmap-item right" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time8") }}</h4>
                  <p>{{ $t("about.ht8") }}</p>
                </div>
              </div>
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time7") }}</h4>
                  <p>{{ $t("about.ht7") }}</p>
                </div>
              </div>
              <div class="roadmap-item right" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time6") }}</h4>
                  <p>{{ $t("about.ht6") }}</p>
                </div>
              </div>
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time5") }}</h4>
                  <p>{{ $t("about.ht5") }}</p>
                </div>
              </div>
              <div class="roadmap-item right" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time4") }}</h4>
                  <p>{{ $t("about.ht4") }}</p>
                </div>
              </div>
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time3") }}</h4>
                  <p>{{ $t("about.ht3") }}</p>
                </div>
              </div>
              <div class="roadmap-item right" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time2") }}</h4>
                  <p>{{ $t("about.ht2") }}</p>
                </div>
              </div>
              <div class="roadmap-item left" data-aos="fade-up">
                <div class="roadmap-text">
                  <h4>{{ $t("about.h_time1") }}</h4>
                  <p>{{ $t("about.ht1") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import BackTop from "../../components/content/backTop/BackTop.vue";
export default {
  name: "AboutUs",
  data() {
    return {
    };
  },
  created() {},
  methods: {
  },
  components: {
    BackTop,
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/AboutUs.less";
</style>
